import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import Auth from './Auth';
import Common from './Common';
import DataTable from './DataTable';
import Ingredients from './Ingredients';
import Devices from './Devices';
import MasterDataReducer from './MasterDataReducer';
import mapReducer from './map.reducer';
import orderReducer from './order.reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    device: Devices,
    dataTable: DataTable,
    ingredients: Ingredients,
    masterData: MasterDataReducer,
    mapData: mapReducer,
    order: orderReducer,
  });
