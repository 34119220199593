import React, { useEffect } from 'react';
import CounterCard from '../../../../@jumbo/components/Common/CounterCard';

const RecipesCounterCard = ({ dashboardDetail }) => {
  useEffect(() => {}, [dashboardDetail]);
  return (
    <CounterCard
      icon={'/images/dashboard/icons8-paint-palette-64.png'}
      number={dashboardDetail?.recipesCount || 0}
      label="Recipes"
      labelProps={{
        fontSize: 16,
      }}
      backgroundColor={['#C076FB -18.96%', '#7717C2 108.17%']}
      gradientDirection="180deg"
    />
  );
};

export default RecipesCounterCard;
