import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';

const Reports = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${requestedUrl}/device-status-history`} component={lazy(() => import('./DeviceStatusHistory'))} />
        <Route path={`${requestedUrl}/refill-queue-history`} component={lazy(() => import('./RefillQueueHistory/index'))} />
        <Route
          path={`${requestedUrl}/refill-transfer-history`}
          component={lazy(() => import('./RefillTransferHistory/index'))}
        />
        <Route path={`${requestedUrl}/purchase-history`} component={lazy(() => import('./PurchaseHistory/index'))} />
      </Switch>
    </Suspense>
  );
};

export default Reports;
