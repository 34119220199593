import { BASE_URL } from './globals';

export const USER_LOGIN = 'login';
export const GET_USER = 'user';
export const FORGOT_PASSWORD = 'forgot-password';
export const RESET_PASSWORD = 'reset-password';
export const USER_LOGOUT = 'logout';
export const CATEGORY_CONFIG_N_VALUES = 'sys-cat-config-n-values';

//Data Tables

export const SET_DEVICES_BY_DEVICE_TYPE_URL = BASE_URL + 'devices/get-devices-by-device-type';
export const SET_ALL_INGREDIENTS_URL = BASE_URL + 'ingredients/get-all-ingredients';
export const SET_TYPES_BY_CATEGORY_URL = BASE_URL + 'sys-cat-config/get-types-by-category';
export const STORE_PURCHASE_INGREDIENT_URL = BASE_URL + 'inventories/store-purchase-to-inventory';
export const TRANSFER_INGREDIENT_URL = BASE_URL + 'inventories/ingr-refill-process-store-to-store';
export const GET_ALL_DESTINATION_DEVICES_URL = BASE_URL + 'devices/get-all-destination-devices';
export const GET_ALL_SOURSE_DEVICES_URL = BASE_URL + 'devices/get-all-source-devices/';
export const GET_ALL_INGREDIENTS_FOR_SELECTED_DEVICE_URL =
  'invt-td-dvs-ingr-batch-vol-stats/get-ingredients-for-selected-device/';
