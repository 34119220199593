import React, { useEffect } from 'react';
import CounterCard from '../../../../@jumbo/components/Common/CounterCard';

const DevicesCounterCard = ({ dashboardDetail }) => {
  useEffect(() => {}, [dashboardDetail]);
  return (
    <CounterCard
      icon={'/images/dashboard/icons8-device-64.png'}
      number={dashboardDetail?.devicesCount || 0}
      label="Devices"
      labelProps={{
        fontSize: 16,
      }}
      backgroundColor={['#8E49F0 -18.96%', '#4904AB 108.17%']}
      gradientDirection="180deg"
    />
  );
};

export default DevicesCounterCard;
