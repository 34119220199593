import {
  CLOSE_DIALOG,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  OPEN_DIALOG,
  SET_FIELDS_ERROR,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  initialURL: '/',
  error: '',
  message: '',
  loading: false,
  openDialog: false,
  fieldsError: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: '', message: '', loading: true, fieldsError: {} };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', loading: false, message: action.payload, fieldsError: {} };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, message: '', error: action.payload };
    }
    case OPEN_DIALOG: {
      return { ...state, openDialog: true };
    }
    case CLOSE_DIALOG: {
      return { ...state, openDialog: false, fieldsError: {} };
    }
    case SET_FIELDS_ERROR: {
      return { ...state, fieldsError: action.payload };
    }
    default:
      return state;
  }
};
