import React, { useEffect } from 'react';

import CounterCard from '../../../../@jumbo/components/Common/CounterCard';

const OperationalAreasCounterCard = ({ dashboardDetail }) => {
  useEffect(() => {}, [dashboardDetail]);
  return (
    <CounterCard
      icon={'/images/dashboard/icons8-location-64.png'}
      number={dashboardDetail?.areaCount || 0}
      label="Area"
      labelProps={{
        fontSize: 16,
      }}
      backgroundColor={['#2cb527 -18.96%', '#2cb527 108.17%']}
      gradientDirection="180deg"
    />
  );
};

export default OperationalAreasCounterCard;
