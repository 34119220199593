import {
  PASSWORD_RESET,
  SEND_FORGET_PASSWORD_EMAIL,
  SIGNOUT_USER,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  authUser: null,
  loadUser: false,
  send_forget_password_email: false,
  password_reset: false,
  authUserRoles: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      const UpdatedRoles = Object.assign([], state.authUserRoles);
      var xyu = action?.payload?.roles?.map(masterRoles => {
        return masterRoles?.permissions?.map(permision => {
          const index = UpdatedRoles?.findIndex(e => e.id == permision?.id);
          if (index == -1) {
            UpdatedRoles.push({
              id: permision?.id,
              name: permision?.name,
            });
          }
          return null;
        });
      });

      return {
        ...state,
        authUser: action.payload,
        loadUser: true,
        authUserRoles: UpdatedRoles || [],
      };
    }
    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload,
      };
    }
    case PASSWORD_RESET: {
      return {
        ...state,
        password_reset: action.payload,
      };
    }
    case SIGNOUT_USER: {
      return {
        ...state,
        authUser: null,
        loadUser: true,
        authUserRoles: [],
      };
    }
    default:
      return state;
  }
};
