import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { NavLink } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import AuthApis from 'services/api/auth.apis';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles({ variant });

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .required('Required')
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email must be a valid email address'),
    password: Yup.string()
      .matches(
        /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        'Password must contain at least 8 characters, including UPPER/lowerscase, number and special character(#?!@$%^&*-)',
      )
      .min(8)
      .required('Required'),
  });

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={SignInSchema}
        onSubmit={async values => {
          if (values.email === 'injoyppl@gmail.com' && values.password === 'asd@1234asd') {
            try {
              // Simulate successful login for the special case
              await dispatch(
                AuthApis.onLogin({
                  email: values.email,
                  password: values.password,
                }),
              );
              console.log('Special case login successful'); // Redirect to /dashboard page
            } catch (error) {
              // Handle login error here
              console.error('Special case login failed:', error);
            }
          } else {
            try {
              // Regular login process
              await dispatch(
                AuthApis.onLogin({
                  email: values.email,
                  password: values.password,
                }),
              );
              // You might handle success and navigate here, depending on the response
            } catch (error) {
              // Handle login error here
              console.error('Regular login failed:', error);
            }
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Box className={classes.authContent}>
            <Box mb={7}>
              <CmtImage src={'/images/logo.png'} />
            </Box>
            <Typography component="div" variant="h1" className={classes.titleRoot}>
              Login
            </Typography>
            <Box mb={2}>
              <TextField
                label={<IntlMessages id="appModule.email" />}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={errors.email && touched.email}
                helperText={errors.email}
                margin="normal"
                name="email"
                variant="outlined"
                className={classes.textFieldRoot}
              />
            </Box>
            <Box mb={2}>
              <TextField
                error={errors.password && touched.password}
                helperText={errors.password}
                type="password"
                label={<IntlMessages id="appModule.password" />}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                margin="normal"
                name="password"
                variant="outlined"
                className={classes.textFieldRoot}
                onKeyPress={e => {
                  if (e.code === 'Enter') {
                    handleSubmit();
                  }
                }}
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
              <FormControlLabel
                className={classes.formcontrolLabelRoot}
                control={<Checkbox name="checkedA" />}
                label="Remember me"
              />
              <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                <NavLink to="/forgot-password">
                  <IntlMessages id="appModule.forgotPassword" />
                </NavLink>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
              <Button type="submit" onClick={handleSubmit} variant="contained" color="primary">
                <IntlMessages id="appModule.signIn" />
              </Button>

              <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                <NavLink to="/signup">
                  <IntlMessages id="signIn.signUp" />
                </NavLink>
              </Box>
            </Box>

            {/* {dispatch(AuhMethods[method].getSocialMediaIcons())} */}

            <ContentLoader />
          </Box>
        )}
      </Formik>
    </AuthWrapper>
  );
};

export default SignIn;
