import React, { useEffect } from 'react';
import CounterCard from '../../../../@jumbo/components/Common/CounterCard';

const CataloguesCounterCard = ({ dashboardDetail }) => {
  useEffect(() => {}, [dashboardDetail]);
  return (
    <CounterCard
      icon={'/images/dashboard/icons8-moleskine-64.png'}
      number={dashboardDetail?.cataloguesCount || 0}
      label="Catalogue"
      labelProps={{
        fontSize: 16,
      }}
      backgroundColor={['#009900 -18.96%', '#009900 108.17%']}
      gradientDirection="180deg"
    />
  );
};

export default CataloguesCounterCard;
