import { errorAlerts, successAlerts } from 'digifluxComponent/common/SweetAlerts';
import FileSaver from 'file-saver';
import { SetCurrentList, SetListData, SetTableData } from 'redux/actions/datatable.action';
import { BASE_ADMIN_URL } from 'utils/globals';
import { CloseDialog, fetchError, fetchStart, fetchSuccess, SetFieldsError } from '../../redux/actions';
import axios from './config';
const DataTablesApi = {
  getList: (item, perpage, page, orderBy, orderDirection, search, extraFilter) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .get(
            BASE_ADMIN_URL +
              item?.module_slug +
              `?per_page=${perpage || 10}&page=${page + 1 || 1}&orderBy=${orderBy || 'id'}&orderDirection=${orderDirection ||
                'asc'}&search=${search || ''}${
                extraFilter
                  ? Object.keys(extraFilter).map(e => {
                      return `&` + e + '=' + extraFilter[e];
                    })
                  : ''
              }`.replaceAll(',', ''),
          )
          .then(res => {
            if (res.data) {
              dispatch(fetchSuccess());
              dispatch(SetListData(item?.module_slug, res?.data?.data));
            } else {
              dispatch(fetchError(res.data.error));
            }
          })
          .catch(function(error) {
            errorAlerts('Get Action', error?.response?.data.message || error.message);
            dispatch(fetchError(''));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  ListAddToServer: (item, obj, finction) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(BASE_ADMIN_URL + item?.module_slug + '/', obj)
          .then(res => {
            if (res.data) {
              successAlerts('Store Action', res?.data?.message);
              dispatch(fetchSuccess());
              dispatch(CloseDialog());
              dispatch(DataTablesApi.getList(item));
              if (finction) {
                finction();
              }
            } else {
              dispatch(fetchError(res.data.error));
            }
          })
          .catch(function(error) {
            dispatch(SetFieldsError(error?.response?.data?.errors));
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        console.log('error', error);
        dispatch(fetchError(error.message));
      }
    };
  },
  ListDelete: (item, ids) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(BASE_ADMIN_URL + item?.module_slug + '/bulk-destroy', { data: { ids: ids } })
          .then(res => {
            if (res.data) {
              successAlerts('Delete Action', res?.data?.message);
              dispatch(fetchSuccess());
              dispatch(DataTablesApi.getList(item));
            } else {
              dispatch(fetchError(res?.data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(''));
            if (error?.response?.data?.errors?.['data.ids']) {
              errorAlerts('Delete Action', error?.response?.data?.errors?.['data.ids']);
            } else {
              errorAlerts('Delete Action', error?.response?.data.message || error.message);
            }
          });
      } catch (error) {
        if (error?.response?.data?.errors?.['data.ids']) {
          errorAlerts('Delete Action', error?.response?.data?.errors?.['data.ids']);
          dispatch(fetchError(''));
        } else {
          dispatch(fetchError(error.message));
        }
      }
    };
  },
  getById: (item, id, perpage, page, orderBy, orderDirection, search) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .get(
            BASE_ADMIN_URL +
              item?.module_slug +
              '/' +
              id +
              '/' +
              item?.listing_slug +
              `?per_page=${perpage || 10}&page=${page + 1 || 1}&orderBy=${orderBy || 'id'}&orderDirection=${orderDirection ||
                'asc'}&search=${search || ''}`,
          )
          .then(res => {
            if (res.data) {
              dispatch(fetchSuccess());
              dispatch(SetTableData(item?.module_slug, res?.data?.data));
              if (item?.setTitle) {
                dispatch(SetCurrentList(res?.data?.data?.[0]));
              }
            } else {
              dispatch(fetchError(res.data.error));
            }
          })
          .catch(function(error) {
            errorAlerts('Get Action', error?.response?.data.message || error.message);
            dispatch(fetchError(''));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  AddToServer: (item, obj, id, callBackFunction) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(BASE_ADMIN_URL + item?.module_slug + '/', obj)
          .then(res => {
            if (res.data) {
              successAlerts('Store Action', res?.data?.message);
              dispatch(fetchSuccess());
              dispatch(CloseDialog());
              dispatch(DataTablesApi.getById(item, id));
              if (callBackFunction) {
                callBackFunction();
              }
            } else {
              dispatch(fetchError(res.data.error));
            }
          })
          .catch(function(error) {
            dispatch(SetFieldsError(error?.response?.data?.errors));
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  PostToServer: (item, obj, id, callBackFunction) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(BASE_ADMIN_URL + item?.api + '/', obj)
          .then(res => {
            if (res.data) {
              successAlerts('Store Action', res?.data?.message);
              dispatch(fetchSuccess());
              dispatch(CloseDialog());
              dispatch(DataTablesApi.getById(item, id));
              if (callBackFunction) {
                callBackFunction();
              }
            } else {
              dispatch(fetchError(res.data.error));
            }
          })
          .catch(function(error) {
            dispatch(SetFieldsError(error?.response?.data?.errors));
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  EditByID: (item, obj, id, callBackFunction, list) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(BASE_ADMIN_URL + item?.module_slug + '/' + obj?.id, obj)
          .then(res => {
            if (res.data) {
              successAlerts('Edit Action', res?.data?.message);
              dispatch(fetchSuccess());
              dispatch(CloseDialog());
              if (list) {
                dispatch(DataTablesApi.getList(item));
              } else {
                dispatch(DataTablesApi.getById(item, id));
              }

              if (callBackFunction) {
                callBackFunction();
              }
            } else {
              dispatch(fetchError(res.data.error));
            }
          })
          .catch(function(error) {
            dispatch(SetFieldsError(error?.response?.data?.errors));
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        console.log('error', error);
        dispatch(fetchError(error.message));
      }
    };
  },
  deleteByID: (item, ids, id) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(BASE_ADMIN_URL + item?.module_slug + '/bulk-destroy', { data: { ids: ids } })
          .then(res => {
            if (res.data) {
              successAlerts('Delete Action', res?.data?.message);
              dispatch(fetchSuccess());
              dispatch(DataTablesApi.getById(item, id));
            } else {
              dispatch(fetchError(res?.data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(''));
            if (error?.response?.data?.errors?.['data.ids']) {
              errorAlerts('Delete Action', error?.response?.data?.errors?.['data.ids']);
            } else {
              errorAlerts('Delete Action', error?.response?.data.message || error.message);
            }
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  QrCodeDownload: (item, ids) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(
            BASE_ADMIN_URL + item?.module_slug + '/' + item?.listing_slug,
            { data: { ids: ids } },
            {
              responseType: 'blob',
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
          .then(res => {
            if (res.data) {
              successAlerts('QrCode Download Action', res?.data?.message);
              FileSaver.saveAs(
                new Blob([res.data], {
                  type: 'application/pdf',
                }),
                '.pdf',
              );
              dispatch(fetchSuccess());
            } else {
              dispatch(fetchError(res?.data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(''));
            if (error?.response?.data?.errors?.['data.ids']) {
              errorAlerts('QrCode Action', error?.response?.data?.errors?.['data.ids']);
            } else {
              errorAlerts('QrCode Action', error?.response?.data.message || error.message);
            }
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  templateAssign: (item, obj, id, callBackFunction) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(BASE_ADMIN_URL + item?.module_slug + '/store-template-details', obj)
          .then(res => {
            if (res.data) {
              successAlerts('Store Template', res?.data?.message);
              dispatch(fetchSuccess());
              dispatch(CloseDialog());
              dispatch(DataTablesApi.getById(item, id));
              if (callBackFunction) {
                callBackFunction();
              }
            } else {
              dispatch(fetchError(res.data.error));
            }
          })
          .catch(function(error) {
            dispatch(SetFieldsError(error?.response?.data?.errors));
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  IdPost: (item, obj, id, callBackFunction, list) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(BASE_ADMIN_URL + item?.slug + obj?.id)
          .then(res => {
            if (res.data) {
              dispatch(fetchSuccess());
              dispatch(CloseDialog());
              if (list) {
                dispatch(DataTablesApi.getList(item));
              } else {
                dispatch(DataTablesApi.getById(item, id));
              }
              if (callBackFunction) {
                callBackFunction();
              }
            } else {
              dispatch(fetchError(res.data.error));
            }
          })
          .catch(function(error) {
            dispatch(SetFieldsError(error?.response?.data?.errors));
            dispatch(fetchError(error?.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
};

export default DataTablesApi;
