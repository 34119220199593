import { SET_ORDER_DELIVERY_DATA, SET_PLANNED_ORDER_DELIVERY_DATA } from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  orderDelivery: {},
  plannedOrderDelivery: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ORDER_DELIVERY_DATA: {
      return {
        ...state,
        orderDelivery: action.payload,
      };
    }
    case SET_PLANNED_ORDER_DELIVERY_DATA: {
      return {
        ...state,
        plannedOrderDelivery: action.payload,
      };
    }
    default:
      return state;
  }
};
