import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ResetPassword from './Auth/ResetPassword';
import Error404 from './Pages/404';
import Authorization from './Pages/Authorization';
import DashboardPage from './Pages/Dashboard';
import Management from './Pages/Management';
import MasterTable from './Pages/MasterTable';
import Process from './Pages/Process';
import Reports from './Pages/Reports';
import Status from './Pages/Status';
import UserPagesIndex from './Pages/userPages';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
const GuestRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return <Route {...rest} render={props => (authUser ? props?.history.goBack() : <Component {...props} />)} />;
};
const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/dashboard'} />;
  }
  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/process" component={Process} />
        <RestrictedRoute path="/dashboard" component={DashboardPage} />
        <RestrictedRoute path="/reports" component={Reports} />
        <RestrictedRoute path="/Status" component={Status} />
        <RestrictedRoute path="/MasterTable" component={MasterTable} />
        <RestrictedRoute path="/UserPages" component={UserPagesIndex} />
        <RestrictedRoute path="/Management" component={Management} />
        <RestrictedRoute path="/Authorization" component={Authorization} />
        <GuestRoute path="/signin" component={Login} />
        <GuestRoute path="/signup" component={Register} />
        <GuestRoute path="/forgot-password" component={ForgotPasswordPage} />
        <GuestRoute path="/reset-password" component={ResetPassword} />
        <RestrictedRoute path="/UserPages" component={UserPagesIndex} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
