import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClearListData } from 'redux/actions/datatable.action';
import DataTablesApi from 'services/api/dataTables.apis';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import CataloguesCounterCard from './CataloguesCounterCard';
import DevicesCounterCard from './DevicesCounterCard';
import OperationalAreasCounterCard from './OperationalAreasCounterCard';
import RecipesCounterCard from './RecipesCounterCard';

const useStyles = makeStyles(theme => ({
  orderLg2: {
    [theme.breakpoints.up('lg')]: {
      order: 2,
    },
  },
  orderLg1: {
    [theme.breakpoints.up('lg')]: {
      order: 1,
    },
  },
}));

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Dashboard', link: '/dashboard' },
];
const apidata = { module_slug: 'dashboard' };

const DashboardPage = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState('asc');
  const [searchText, setSearch] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { listData } = useSelector(({ dataTable }) => dataTable);
  const classes = useStyles();
  useEffect(() => {
    dispatch(DataTablesApi.getList(apidata, rowsPerPage, page, orderBy, order, searchText));
    return dispatch(ClearListData());
  }, []);
  return (
    <PageContainer heading="Dashboard" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12} xl={8} className={classes.orderLg1}>
          <GridContainer>
            <Grid item xs={12} sm={6} md={3}>
              <DevicesCounterCard dashboardDetail={listData?.dashboard} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CataloguesCounterCard dashboardDetail={listData?.dashboard} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <RecipesCounterCard dashboardDetail={listData?.dashboard} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <OperationalAreasCounterCard dashboardDetail={listData?.dashboard} />
            </Grid>
          </GridContainer>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default DashboardPage;
