import React from 'react';
import {
  PostAdd,
  CategorySharp,
  ChromeReaderMode,
  Dashboard,
  DeviceHub,
  FilterCenterFocus,
  Flip,
  ArrowForward,
  PlaylistAddCheck,
  GridOn,
  Settings,
  Devices,
  LocationSearching,
  LibraryAddCheck,
  Queue,
  ShoppingCart,
  CompareArrows,
  SettingsInputComposite,
  Fastfood,
  LocalDining,
  PermDeviceInformation,
  BorderColor,
  ViewModule,
  PermContactCalendar,
  InsertChart,
  Rotate90DegreesCcw,
  ShoppingBasket,
  Update,
  Map,
  LocalShipping,
} from '@material-ui/icons';
// import FactCheckIcon from '@material-ui/icons/LibraryAddCheck';
import IntlMessages from '../../../utils/IntlMessages';

const adminUser = {
  name: <IntlMessages id={'sidebar.authorization.adminUser'} />,
  type: 'item',
  icon: <PermContactCalendar />,
  link: '/Authorization/AdminUserPage',
  permission: 'admin.admin-user.index',
};
const module = {
  name: <IntlMessages id={'sidebar.modules'} />,
  type: 'item',
  icon: <ViewModule />,
  link: '/Authorization/Modules',
  permission: 'admin.module-name-mapping.index',
};
const roles = {
  name: <IntlMessages id={'sidebar.authorization.roles'} />,
  type: 'item',
  icon: <Queue />,
  link: '/Authorization/Roles',
  permission: 'admin.role.index',
};
const permission = {
  name: <IntlMessages id={'sidebar.authorization.permission'} />,
  type: 'item',
  icon: <BorderColor />,
  link: '/Authorization/PermissionPage',
  permission: 'admin.permission.index',
};
const FinanceCategory = {
  name: <IntlMessages id={'sidebar.authorization.financeCategory'} />,
  type: 'item',
  icon: <BorderColor />,
  link: '/Authorization/FinanceCategoryPage',
  permission: 'admin.permission.index',
};
const FinanceAccount = {
  name: <IntlMessages id={'sidebar.authorization.financeAccount'} />,
  type: 'item',
  icon: <BorderColor />,
  link: '/Authorization/FinanceAccount',
  permission: 'admin.permission.index',
};
const GeoFenceFeature = {
  name: <IntlMessages id={'sidebar.authorization.GeoFenceFeature'} />,
  type: 'item',
  icon: <BorderColor />,
  link: '/Authorization/GeoFenceFeature',
  permission: 'admin.permission.index',
};
const sysCategoryConfigMenu = {
  name: <IntlMessages id={'sidebar.masterTable.categoryConfig'} />,
  type: 'item',
  icon: <CategorySharp />,
  link: '/MasterTable/CategoryConfig',
  permission: 'admin.admin.sys-cat-config-n-value.index',
};
const deliveryChargesMenu = {
  name: <IntlMessages id={'sidebar.masterTable.DeliveryCharges'} />,
  type: 'item',
  icon: <CategorySharp />,
  link: '/MasterTable/DeliveryCharges',
  permission: 'admin.admin.delivery-charges.index',
};
const sysCanCupConfigMenu = {
  name: <IntlMessages id={'sidebar.masterTable.CanCupConfig'} />,
  type: 'item',
  icon: <CategorySharp />,
  link: '/MasterTable/CanCupConfig',
  permission: 'admin.cart_mg_cancup_size_config.index',
};
const accountCategoryConfigMenu = {
  name: <IntlMessages id={'sidebar.masterTable.AccountCategoryConfig'} />,
  type: 'item',
  icon: <CategorySharp />,
  link: '/MasterTable/AccountCategoryConfig',
  permission: 'admin.fin-acc-category-configs.index',
};
const statusConfig = {
  name: <IntlMessages id={'sidebar.masterTable.statusConfig'} />,
  type: 'item',
  icon: <LibraryAddCheck />,
  link: '/MasterTable/StatusConfig',
  permission: 'admin.sys-cat-values-n-act-link.index',
};
const scheduleDetails = {
  name: <IntlMessages id={'sidebar.masterTable.schedulerDetails'} />,
  type: 'item',
  icon: <ChromeReaderMode />,
  link: '/MasterTable/SchedulerDetails',
  permission: 'admin.schl-mg-schedule-master.index',
};
const qrConfig = {
  name: <IntlMessages id={'sidebar.masterTable.qrConfig'} />,
  type: 'item',
  icon: <Flip />,
  link: '/MasterTable/QrConfig',
  permission: 'admin.sys-qr-config.index',
};
const area = {
  name: <IntlMessages id={'sidebar.masterTable.area'} />,
  type: 'item',
  icon: <LocationSearching />,
  link: '/MasterTable/Area',
  permission: 'admin.loca-mg-area-master.index',
};
const deviceMenu = {
  name: <IntlMessages id={'sidebar.management.devices'} />,
  type: 'item',
  icon: <Devices />,
  link: '/Management/Devices',
  permission: 'admin.advs-mg-device.index',
};
const partseMenu = {
  name: <IntlMessages id={'sidebar.management.parts'} />,
  type: 'item',
  icon: <Settings />,
  link: '/Management/Parts',
  permission: 'admin.aprt-mg-part-digital-id.index',
};
const ingredientsMenu = {
  name: <IntlMessages id={'sidebar.management.ingredients'} />,
  type: 'item',
  icon: <LocalDining />,
  link: '/Management/Ingredients',
  permission: 'admin.ingr-mg-ingredient.index',
};
const processPointsMenu = {
  name: <IntlMessages id={'sidebar.management.processPoints'} />,
  type: 'item',
  icon: <SettingsInputComposite />,
  link: '/Management/ProcessPoints',
  permission: 'admin.advs-mv-device-io-dock.index',
};
const entityApprovalMenu = {
  name: <IntlMessages id={'sidebar.management.entityApproval'} />,
  type: 'item',
  icon: <SettingsInputComposite />,
  link: '/Management/EntityApproval',
  permission: 'admin.fin-entity-masters.index',
};
const DockTemplateMenu = {
  name: <IntlMessages id={'sidebar.management.Templates'} />,
  type: 'item',
  icon: <DeviceHub />,
  link: '/Management/Templates',
  permission: 'admin.template-device-inlet-outlet.index',
};
const checklistMenu = {
  name: <IntlMessages id={'sidebar.management.checklist'} />,
  type: 'item',
  icon: <PlaylistAddCheck />,
  link: '/Management/checklist',
  permission: 'admin.advs-mv-inspect-template-detail.index',
};
const QrCodeMasterMenu = {
  name: <IntlMessages id={'sidebar.management.QRCodePage'} />,
  type: 'item',
  icon: <FilterCenterFocus />,
  link: '/Management/QRCodesMaster',
  permission: 'admin.--.index',
};
const CatalogueMenu = {
  name: <IntlMessages id={'sidebar.management.Catalogue'} />,
  type: 'item',
  icon: <GridOn />,
  link: '/Management/Catalogue',
  permission: 'admin.catalogue-mg-master.index',
};
const IngredientsTransferMenu = {
  name: <IntlMessages id={'sidebar.process.IngredientsTransfer'} />,
  type: 'item',
  icon: <CompareArrows />,
  link: '/process/ingredients-transfer',
  permission: 'admin',
};
const recipeMenu = {
  name: <IntlMessages id={'sidebar.management.recipe'} />,
  type: 'item',
  icon: <Fastfood />,
  link: '/Management/Recipes',
  permission: 'admin.ingr-mg-recipe-catalogue.index',
};
const PurchaseIngredientsMenu = {
  name: <IntlMessages id={'sidebar.process.PurchaseIngredients'} />,
  type: 'item',
  icon: <ShoppingCart />,
  link: '/process/purchase-ingredients',
  permission: 'admin',
};
const RefillQueueHistoryMenu = {
  name: <IntlMessages id={'sidebar.management.RefillQueueHistory'} />,
  type: 'item',
  icon: <Update />,
  link: '/reports/refill-queue-history',
  permission: 'admin',
};
const deviceServiceHistory = {
  name: <IntlMessages id={'sidebar.deviceStatusHistory'} />,
  type: 'item',
  icon: <PermDeviceInformation />,
  link: '/reports/device-status-history',
  permission: 'admin',
};
const RefillTransferHistoryMenu = {
  name: <IntlMessages id={'sidebar.management.RefillTransferHistory'} />,
  type: 'item',
  icon: <Rotate90DegreesCcw />,
  link: '/reports/refill-transfer-history',
  permission: 'admin',
};
const PurchaseHistoryMenu = {
  name: <IntlMessages id={'sidebar.management.PurchaseHistory'} />,
  type: 'item',
  icon: <ShoppingBasket />,
  link: '/reports/purchase-history',
  permission: 'admin',
};
const dashboardNavs = [
  {
    name: <IntlMessages id={'pages.dashboardPage'} />,
    type: 'item',
    icon: <Dashboard />,
    link: '/dashboard',
    permission: 'admin',
  },
];
const currentStockOfDevice = {
  name: <IntlMessages id={'sidebar.CurrentStock'} />,
  type: 'item',
  icon: <InsertChart />,
  link: '/Status/current-stock-of-Devices',
  permission: 'admin',
};
const rangeMap = {
  name: <IntlMessages id={'sidebar.rangeMap'} />,
  type: 'item',
  icon: <Map />,
  link: '/Status/range-map',
  permission: 'admin',
};
const devicesMap = {
  name: <IntlMessages id={'sidebar.devicesMap'} />,
  type: 'item',
  icon: <Map />,
  link: '/Status/devices-location',
  permission: 'admin',
};
export const sidebarNavs = [
  {
    name: <IntlMessages id={'sidebar.dashboard'} />,
    type: 'section',
    children: dashboardNavs,
  },
  {
    name: <IntlMessages id={'sidebar.authorization'} />,
    type: 'section',
    children: [module, roles, adminUser, permission, FinanceCategory, FinanceAccount, GeoFenceFeature],
  },
  {
    name: <IntlMessages id={'sidebar.management'} />,
    type: 'section',
    children: [
      partseMenu,
      DockTemplateMenu,
      deviceMenu,
      processPointsMenu,
      ingredientsMenu,
      recipeMenu,
      CatalogueMenu,
      checklistMenu,
      QrCodeMasterMenu,
      entityApprovalMenu
    ],
  },
  {
    name: <IntlMessages id={'sidebar.process'} />,
    type: 'section',
    children: [PurchaseIngredientsMenu, IngredientsTransferMenu],
  },
  {
    name: <IntlMessages id={'sidebar.Status'} />,
    type: 'section',
    children: [currentStockOfDevice, rangeMap, devicesMap],
  },
  {
    name: <IntlMessages id={'sidebar.Reports'} />,
    type: 'section',
    children: [RefillQueueHistoryMenu, RefillTransferHistoryMenu, PurchaseHistoryMenu, deviceServiceHistory],
  },
  {
    name: <IntlMessages id={'sidebar.masterTables'} />,
    type: 'section',
    children: [
      sysCategoryConfigMenu,
      statusConfig,
      deliveryChargesMenu,
      sysCanCupConfigMenu,
      scheduleDetails,
      qrConfig,
      area,
      accountCategoryConfigMenu
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/dashboard',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/dashboard',
      },
    ],
  },
];
