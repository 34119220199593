import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';

const ResetPassword = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${requestedUrl}/:slug`} component={lazy(() => import('./[slug]'))} />
        {/* <Route component={lazy(() => import('../404'))} /> */}
      </Switch>
    </Suspense>
  );
};

export default ResetPassword;
