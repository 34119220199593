import {
  CLEAR_CURRENT_LIST_TITLE,
  CLEAR_DATA_TABLE,
  CLEAR_LIST_DATA,
  SET_CURRENT_LIST,
  SET_DATA_TABLE_DATA,
  SET_LIST_DATA,
} from '../../@jumbo/constants/ActionTypes';
export const SetListData = (slug, data) => {
  return dispatch => {
    dispatch({
      type: SET_LIST_DATA,
      module_slug: slug,
      payload: data,
    });
  };
};
export const ClearListData = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_LIST_DATA,
    });
  };
};
export const SetTableData = (slug, data) => {
  return dispatch => {
    dispatch({
      type: SET_DATA_TABLE_DATA,
      module_slug: slug,
      payload: data,
    });
  };
};
export const ClearTableData = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_DATA_TABLE,
    });
  };
};
export const SetCurrentList = data => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_LIST,
      payload: data,
    });
  };
};
export const ClearCurrentListTitle = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_CURRENT_LIST_TITLE,
    });
  };
};
