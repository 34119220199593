import { CLEAR_MASTER_DATA, SET_MASTER_DATA } from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  masterData: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MASTER_DATA: {
      const tableData = state.masterData;
      tableData[`${action?.item.storeKey}`] = action.payload;
      return {
        ...state,
        masterData: tableData,
      };
    }
    case CLEAR_MASTER_DATA: {
      const tableData = state.masterData;
      delete tableData[`${action?.storeKey}`];
      return {
        ...state,
        masterData: tableData,
      };
    }
    default:
      return state;
  }
};
