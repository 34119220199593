import {
  CLEAR_ALL_SOURSE_DEVICES,
  CLEAR_INGREDIENTS_FOR_SELECTED_DEVICE,
  SET_ALL_DESTINATION_DEVICES,
  SET_ALL_SOURSE_DEVICES,
  SET_DEVICES_BY_DEVICE_TYPE,
  SET_INGREDIENTS_FOR_SELECTED_DEVICE,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  devicesByDeviceTypeData: [],
  alldestinationDevices: [],
  allSourseDevices: [],
  allIngredients: [],
  // loadDevice: false,
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DEVICES_BY_DEVICE_TYPE: {
      return {
        ...state,
        devicesByDeviceTypeData: action.payload,
        // loadDevice: loadDevice,
      };
    }
    case SET_ALL_DESTINATION_DEVICES: {
      return {
        ...state,
        alldestinationDevices: action.payload,
        allSourseDevices: [],
        allIngredients: [],
      };
    }
    case SET_ALL_SOURSE_DEVICES: {
      return {
        ...state,
        allSourseDevices: action.payload,
        allIngredients: [],
      };
    }
    case SET_INGREDIENTS_FOR_SELECTED_DEVICE: {
      return {
        ...state,
        allIngredients: action.payload,
      };
    }
    case CLEAR_INGREDIENTS_FOR_SELECTED_DEVICE: {
      return {
        ...state,
        allIngredients: [],
      };
    }
    case CLEAR_ALL_SOURSE_DEVICES: {
      return {
        ...state,
        allSourseDevices: [],
      };
    }
    default:
      return state;
  }
};
