import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';

const Authorization = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${requestedUrl}/Modules`} component={lazy(() => import('./ModulePage'))} />
        <Route path={`${requestedUrl}/AdminUserPage`} component={lazy(() => import('./AdminUserPage'))} />
        <Route path={`${requestedUrl}/PermissionPage`} component={lazy(() => import('./PermissionPage'))} />
        <Route path={`${requestedUrl}/FinanceCategoryPage`} component={lazy(() => import('./FinanceCategoryPage'))} />
        <Route path={`${requestedUrl}/FinanceAccount`} component={lazy(() => import('./FinanceAccount/index'))} />
        <Route path={`${requestedUrl}/Roles`} component={lazy(() => import('./Roles/index'))} />
        <Route path={`${requestedUrl}/GeoFenceFeature`} component={lazy(() => import('./CreateFenceFeature'))} />
        <Route component={lazy(() => import('../404'))} />
      </Switch>
    </Suspense>
  );
};

export default Authorization;
