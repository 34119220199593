import {
  CLEAR_CURRENT_LIST_TITLE,
  CLEAR_DATA_TABLE,
  CLEAR_LIST_DATA,
  SET_CURRENT_LIST,
  SET_DATA_TABLE_DATA,
  SET_LIST_DATA,
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  dataTableItems: {},
  listData: {},
  currentList: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DATA_TABLE_DATA: {
      const tableData = state.dataTableItems;
      tableData[`${action.module_slug}`] = action.payload;
      return {
        ...state,
        dataTableItems: tableData,
      };
    }
    case CLEAR_DATA_TABLE: {
      return {
        ...state,
        dataTableItems: {},
      };
    }
    case SET_LIST_DATA: {
      const tableData = state.listData;
      tableData[`${action.module_slug}`] = action.payload;
      return {
        ...state,
        listData: tableData,
      };
    }
    case CLEAR_LIST_DATA: {
      return {
        ...state,
        listData: {},
        currentList: null,
      };
    }
    case SET_CURRENT_LIST: {
      return {
        ...state,
        currentList: action.payload,
      };
    }
    case CLEAR_CURRENT_LIST_TITLE: {
      return {
        ...state,
        currentList: null,
      };
    }
    default:
      return state;
  }
};
