import { BASE_ADMIN_URL } from 'utils/globals';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { logout, resetPassword, setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../redux/actions/Auth';
import { FORGOT_PASSWORD, GET_USER, RESET_PASSWORD, USER_LOGIN, USER_LOGOUT } from '../../utils/api-urls';
import axios from './config';

const AuthApis = {
  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(BASE_ADMIN_URL + USER_LOGIN, {
            email: email,
            password: password,
          })
          .then(({ data }) => {
            if (data.data?.user) {
              localStorage.setItem('token', data.data['access-token']);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.data['access-token'];
              dispatch(fetchSuccess());
              dispatch(AuthApis.getAuthUser(true, data.data['access-token']));
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post(BASE_ADMIN_URL + USER_LOGOUT)
        .then(data => {
          if (data) {
            dispatch(fetchSuccess());
            localStorage.removeItem('token');
            dispatch(logout());
            console.log('onLogout action dispatched');
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (localStorage.getItem('token')) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get(BASE_ADMIN_URL + GET_USER)
        .then(({ data }) => {
          if (data?.data.user) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data?.data.user));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: ({ email }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(BASE_ADMIN_URL + FORGOT_PASSWORD, {
            email: email,
          })
          .then(({ data }) => {
            dispatch(fetchSuccess());
            dispatch(setForgetPassMailSent(true));
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onResetPassword: ({ email, password, confirmPassword, token }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(BASE_ADMIN_URL + RESET_PASSWORD, {
            email: email,
            password: password,
            password_confirmation: confirmPassword,
            token: token,
          })
          .then(({ data }) => {
            dispatch(fetchSuccess());
            dispatch(resetPassword(true));
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
      setTimeout(() => {
        dispatch(fetchSuccess());
      }, 300);
    };
  },
};

export default AuthApis;
