import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';

const Management = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${requestedUrl}/Catalogue`} component={lazy(() => import('./Catalogue/index'))} />
        <Route path={`${requestedUrl}/Parts`} component={lazy(() => import('./Parts/index'))} />
        <Route path={`${requestedUrl}/Devices`} component={lazy(() => import('./Devices/index'))} />
        <Route path={`${requestedUrl}/Ingredients`} component={lazy(() => import('./Ingredients/index'))} />
        <Route path={`${requestedUrl}/Recipes`} component={lazy(() => import('./Recipes/index'))} />
        <Route path={`${requestedUrl}/ProcessPoints`} component={lazy(() => import('./ProcessPoints/index'))} />
        <Route path={`${requestedUrl}/Checklist`} component={lazy(() => import('./Checkelist/index'))} />
        <Route path={`${requestedUrl}/Templates`} component={lazy(() => import('./Templates/index'))} />
        <Route path={`${requestedUrl}/QRCodesMaster`} component={lazy(() => import('./QRCodeMasterPage'))} />
        <Route path={`${requestedUrl}/EntityApproval`} component={lazy(() => import('./EntityApproval/EntityApprovalPage'))} />
        <Route component={lazy(() => import('../404'))} />
      </Switch>
    </Suspense>
  );
};

export default Management;
