import { Collapse, IconButton, Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthApis from 'services/api/auth.apis';
import CmtImage from '../../../../@coremat/CmtImage';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import IntlMessages from '../../../utils/IntlMessages';
import ContentLoader from '../../ContentLoader';
import AuthWrapper from './AuthWrapper';
import { Formik } from 'formik';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  useEffect(() => {
    let timeOutStopper = null;
    if (send_forget_password_email) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        history.push('/');
      }, 3000);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_forget_password_email]);

  const ForgotpasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required('Required')
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email must be a valid email address'),
  });

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'}>
          <CmtImage src={'/images/logo.png'} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          ForgotPassword
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            A mail has been sent on your email address with reset password link.
          </Alert>
        </Collapse>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={ForgotpasswordSchema}
          onSubmit={values => {
            dispatch(
              AuthApis.onForgotPassword({
                email: values.email,
              }),
            );
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form>
              <div className={'mb-5'}>
                <TextField
                  error={errors.email && touched.email}
                  helperText={errors.email}
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  margin="normal"
                  variant="outlined"
                  name="email"
                  className={classes.textFieldRoot}
                />
              </div>
              <div className={'mb-5'}>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                  <IntlMessages id="appModule.resetPassword" />
                </Button>
              </div>

              <div>
                <Typography>
                  Don't remember your email?
                  <span className={'ml-2'}>
                    <Link href="#">
                      <IntlMessages id="appModule.contactSupport" />
                    </Link>
                  </span>
                </Typography>
              </div>
            </form>
          )}
        </Formik>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
