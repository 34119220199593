import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';

const Status = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${requestedUrl}/current-stock-of-Devices`} component={lazy(() => import('./stockOfDevices'))} />
        <Route path={`${requestedUrl}/range-map`} component={lazy(() => import('./mapAreaRange/indx'))} />
        <Route path={`${requestedUrl}/devices-location`} component={lazy(() => import('./devicesLocation'))} />
        <Route component={lazy(() => import('../404'))} />
      </Switch>
    </Suspense>
  );
};

export default Status;
