import { useSelector } from 'react-redux';

export function CheckPermissions(permissionName) {
  // const { authUserRoles } = useSelector(({ auth }) => auth);
  // if (permissionName) {
  //   return authUserRoles?.find(e => e.name === permissionName) ? true : false;
  // }
  return true;
}
function getMiddle(prop, markers) {
  let values = markers.map(m => m[prop]);
  let min = Math.min(...values);
  let max = Math.max(...values);
  if (prop === 'lng' && max - min > 180) {
    values = values.map(val => (val < max - 180 ? val + 360 : val));
    min = Math.min(...values);
    max = Math.max(...values);
  }
  let result = (min + max) / 2;
  if (prop === 'lng' && result > 180) {
    result -= 360;
  }
  return result;
}

export function findCenter(markers) {
  return {
    lat: getMiddle('lat', markers),
    lng: getMiddle('lng', markers),
  };
}
export function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

export function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export function union(a, b) {
  return [...a, ...not(b, a)];
}
