import { CLEAR_TREE_DATA, SET_MASTER_TREE_NODE_DATA, SET_TREE, SET_TREE_ACTIVE_NODE } from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  masterTreeNode: [],
  treeNodes: [],
  activeNode: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MASTER_TREE_NODE_DATA: {
      return {
        ...state,
        masterTreeNode: action.payload,
      };
    }
    case SET_TREE: {
      return {
        ...state,
        treeNodes: action.payload,
      };
    }
    case SET_TREE_ACTIVE_NODE: {
      return {
        ...state,
        activeNode: action.payload,
      };
    }
    case CLEAR_TREE_DATA: {
      return {
        ...state,
        masterTreeNode: [],
        treeNodes: [],
      };
    }
    default:
      return state;
  }
};
