import {
  CLOSE_DIALOG,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  OPEN_DIALOG,
  SET_FIELDS_ERROR,
} from '../../@jumbo/constants/ActionTypes';

export const fetchSuccess = message => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || '',
    });
  };
};
export const fetchError = error => {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
  };
};
export const OpenDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_DIALOG,
    });
  };
};
export const CloseDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_DIALOG,
    });
  };
};
export const SetFieldsError = errorobj => {
  return dispatch => {
    dispatch({
      type: SET_FIELDS_ERROR,
      payload: errorobj,
    });
  };
};
