import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

export const deleteAlerts = (title, callBack) => {
  MySwal.fire({
    icon: 'question',
    title: 'Delete Action',
    text: `Are you sure you want to delete ${title}?`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: 'red',
    confirmButtonText: 'Yes',
  }).then(value => {
    if (value?.isConfirmed) {
      callBack();
    } else {
      console.log('delete else', value);
    }
  });
};
export const errorAlerts = (title, text) => {
  MySwal.fire({
    icon: 'error',
    title: title,
    text: text,
  });
};
export const successAlerts = (title, text) => {
  MySwal.fire({
    icon: 'success',
    title: title,
    text: text,
  });
};
